const data = [
    {
        id: 'A',
        name: 'Ativo',
        class: 'green'
    },
    {
        id: 'I',
        name: 'Inativo',
        class: 'red'
    },
    {
        id: 'D',
        name: 'Excluído',
        class: 'grey'
    }
];

export default [...data];