<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">Detalhe do dispositivo</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn type="button" outlined @click="goBack">Voltar</v-btn>
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-auth="'STREAMING_DEVICE.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-auth="'STREAMING_DEVICE.UPDATE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="releaseIp"
        v-auth="'STREAMING_DEVICE.RELEASE_IP'"
        :loading="releaseIpButton.loading"
        >Liberar IP</v-btn
      >
    </ButtonBar>

    <v-form v-model="validForm">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Dados básicos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Provedor"
                    v-model="device.provider_name"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Assinante"
                    :value="fullname"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Modelo"
                    v-model="device.model"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Identificador"
                    v-model="device.identifier"
                    maxlength="6"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="MAC Address"
                    maxlength="60"
                    v-model="device.mac_address"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="IP"
                    v-model="device.ip"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Serial"
                    v-model="device.serial_number"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" v-auth="'SUBSCRIBER.UPDATE'">
                  <v-switch
                    :input-value="device.status === 'A'"
                    @change="changeStatus($event)"
                  >
                    <template v-slot:label>
                      {{ device.status | enum(data.streamingDeviceStatus) }}
                      <v-progress-circular
                        v-if="changingStatus"
                        :indeterminate="changingStatus"
                        :value="0"
                        size="24"
                        class="ml-2"
                      ></v-progress-circular>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import streamingDeviceApi from "@/common/api/streaming-device.api";
import streamingDeviceStatus from "@/common/data/streaming-device-status.data";
import ButtonBar from "@/components/layout/ButtonBar";
import toaster from "@/common/util/toaster";

export default {
  name: "StreamingDeviceDetails",

  components: {
    ButtonBar,
  },

  data() {
    return {
      data: {
        streamingDeviceStatus: streamingDeviceStatus,
      },
      validForm: true,
      changingStatus: false,
      device: {
        provider: {},
        subscriber: {},
      },
      updateButton: {
        loading: false,
      },
      removeButton: {
        loading: false,
      },
      releaseIpButton: {
        loading: false,
      },
    };
  },

  computed: {
    fullname: function() {
      return `${this.device.subscriber_first_name || ""}  ${this.device
        .subscriber_last_name || ""}`;
    },
  },

  created() {
    this.load(this.$route.params.id);
  },

  methods: {
    load(deviceId) {
      streamingDeviceApi.get(deviceId).then((result) => {
        this.device = result.data;
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    async releaseIp() {
      if (
        await this.$root.$confirm(this.validForm, "streamingDevice.releaseIp")
      ) {
        let data = {
          action: "release_ip",
        };

        this.releaseIpButton.loading = true;

        streamingDeviceApi
          .changeStatus(this.device.subscriber_id, this.device.id, data)
          .then(() => {
            this.releaseIpButton.loading = false;

            toaster.show("IP liberado com sucesso!");
          })
          .catch(() => (this.releaseIpButton.loading = false));
      }
    },

    async changeStatus(value) {
      let data = {
        action: value ? "enable" : "disable",
      };

      let actionText = value ? "habilitado" : "desabilitado";

      streamingDeviceApi
        .changeStatus(this.device.subscriber_id, this.device.id, data)
        .then((response) => {
          this.changingStatus = false;
          this.device.status = response.data.status;

          toaster.show(`Dispositivo ${actionText} com sucesso!`);
        })
        .catch(() => {
          this.changingStatus = false;
          this.device.status_switch = !this.device.status_switch;
        });
    },

    async update() {
      if (await this.$root.$confirm(true, "streamingDevice.update")) {
        this.updateButton.loading = true;

        streamingDeviceApi
          .update(this.device.id, { identifier: this.device.identifier })
          .then(() => {
            this.updateButton.loading = false;

            toaster.show("Dispositivo alterado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "streamingDevice.remove")) {
        this.removeButton.loading = true;

        streamingDeviceApi
          .remove(this.device.id)
          .then(() => {
            this.removeButton.loading = false;

            toaster.show("Dispositivo excluído com sucesso!");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
