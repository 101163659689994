import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('streamingDevice', 'list', params);
};

const get = (deviceId: string): Promise<any> => {
    const params = {
        query: {
            deviceId
        },
    };

    return httpClient.invoke('streamingDevice', 'get', params)
};

const changeStatus = (subscriberId: string, deviceId: string, data: any): Promise<any> => {
    const params = {
        query: {
            subscriberId,
            deviceId
        },
        data: data
    };

    return httpClient.invoke('streamingDevice', 'changeStatus', params)
};

const update = (deviceId: string, data: any): Promise<any> => {
    const params = {
        query: {
            deviceId
        },
        data: data
    };

    return httpClient.invoke('streamingDevice', 'update', params)
};

const remove = (deviceId: string): Promise<any> => {
    const params = {
        query: {
            deviceId
        }
    };

    return httpClient.invoke('streamingDevice', 'remove', params)
};

export default {
    list,
    get,
    update,
    changeStatus,
    remove
}